nav{
    padding: 0 2.4rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0.5px 4px;
}

.navTitle{
    font-size: 3.6rem;
    font-weight: 500;
}

.logoutButton {
    width: 100px;
    height: 50px;
    font-size: 1.8rem;
    margin: 0px !important;
    background: #fff;
    border: 0px;
    cursor: pointer;
}
