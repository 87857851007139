.App .card{
    color: inherit;
    background-color: inherit;
    border-radius: 5px;
    transition: 0.1s linear all;
    white-space: pre-line;
}

.note{
    border-radius: 5px;
    transition: 0.1s linear all;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.note > header,.eidt-form > header{
    font-size:2.4rem;
    padding: 0 24px;
}

.note > article,.eidt-form > article{
    font-size:2rem ;
    padding:24px;
    line-height: 1.6;
}

.note-action-row {
    padding:16px;
}


.App-dark .card
{
    background-color: var(--btn-dark-hover);
}

.App-light .card {
    background-color: var(--btn-light-hover);
}

.App-dark .note
{
    background-color: var(--btn-dark);
}

.App-light .note {
    background-color: var(--btn-light);
}

.eidt-form > header{
    padding: 12px 24px;
}